const data = {
  code: 0,
  data: {
    page: {
      total: 15,
      pageTotal: 10,
      pageNum: 1,
      pageSize: 10,
    },
    list: [
      {
        id: 15,
        startTime: 1679381700000,
        className: "班次2",
      },
      {
        id: 14,
        startTime: 1676218320000,
        className: "班次1",
      },
      {
        id: 13,
        startTime: 1675008720000,
        className: "班次1",
      },
      {
        id: 12,
        startTime: 1673280720000,
        className: "班次1",
      },
      {
        id: 11,
        startTime: 1661324100000,
        className: "班次2",
      },
      {
        id: 10,
        startTime: 1661262960000,
        className: "班次4",
      },
      {
        id: 9,
        startTime: 1660839120000,
        className: "班次1",
      },
      {
        id: 8,
        startTime: 1660805700000,
        className: "班次2",
      },
      {
        id: 7,
        startTime: 1660752720000,
        className: "班次1",
      },
      {
        id: 6,
        startTime: 1660744560000,
        className: "班次4",
      },
    ],
  },
};

export { data };

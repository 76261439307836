<template>
  <div class="maidong">
    <div class="tabs">
      <div @click="() => handleClick('1')" class="tab-item" :class="{ active: activeName == '1' }">
        <span>奶厅1</span>
      </div>
      <div @click="() => handleClick('2')" class="tab-item" :class="{ active: activeName == '2' }">
        <span>奶厅2</span>
      </div>
    </div>

    <div class="title-box">
      <div class="text">班次1脉动监测进行中</div>
      <div class="history-btn" @click="shitHistory">班次历史</div>

    </div>

    <div class="disk-wrapper">
      <disks :disks="disks"></disks>
    </div>

    <history-modal v-model:visible="historyVisible" />
  </div>
</template>

<script>
import disks from "../components/disks";
import HistoryModal from "../components/historyModal";

import {data} from './data';

export default {
  components: { disks,HistoryModal },
  data() {
    return {
      activeName: '1',
      // disks: [
      //   {
      //     id: 1,
      //     seats: [
      //       {
      //         id: 1,
      //         mhi: "10.0",
      //         status: 1
      //       }
      //     ]
      //   }
      // ]
      disks: data.data.disks,
      historyVisible: false,
    }
  },
  methods: {
    handleClick(name) {
      this.activeName = name;
    },
    shitHistory() {
      this.historyVisible = true;
    },
  },
  mounted() { }
}


</script>



<style scoped lang="less" src="./index.less"></style>
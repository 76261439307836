<template>
  <el-dialog  v-model="visible"  title="班次历史" class="history-modal" :before-close="handleClose">
    <!-- <template #header>
      <div class="text-box">
        <span class="h-l"></span>
        <span class="header-text">班次历史</span>
        <span class="h-r"></span>
      </div>
    </template> -->
    <div class="history">
      <div class="history-con">
        <el-table border :data="tableData">
          <el-table-column prop="id" label="序号" width="100%">
          </el-table-column>
          <el-table-column prop="startTime" label="日期" :formatter="formatterTime"></el-table-column>
          <el-table-column prop="className" label="班次"> </el-table-column>

          <el-table-column label="操作" width="180%">
            <template #default="scope">
              <div class="detail-btn" @click="look(scope.$index, scope.row)">
                查看结果
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination v-if="pageRes.total > 10" background layout="prev, pager, next" :total="pageRes.total"
          class="page mt20" v-model:currentPage="page.pageNum" @current-change="handleCurrentChange"
          :page-size="page.pageSize">
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
import { data } from './data'

export default {
  name: "HistoryModal",
  props: {
    visible: Boolean,
    id: String,
  },
  data() {
    return {
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      pageRes: {
        total: 0,
      },
    };
  },
  watch: {
    visible: async function (nV, oV) {
      if (nV) {
        console.log("打开弹窗-班次历史");
        await this.getList();
      } else {
        console.log("关闭弹窗-班次历史");
      }
    },
  },
  methods: {
    async getList() {
      // try {
      //   let res = await this.$myRequest({
      //     url: "/getHistoryDataReq",
      //     method: "post",
      //     data: {
      //       page: this.page,
      //     },
      //     type: 'json'
      //   });
      //   res = res.data;
      let res = data
      this.pageRes = res.data.page;
      this.tableData = res.data.list;
      // } catch (err) {
      //   console.log(err);
      // }
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    look(index, row) {
      this.handleClose();
      this.$router.push({
        name: "SeatSelect",
        params: { historyId: row.id },
      });
    },
    formatterMode(row, column) {
      let option = { 0: "手动", 1: "自动" };

      return option[row.mode];
    },
    formatterTarget(row, column) {
      if (row.mode == 0) {
        return `${row.diskId}号转盘${row.seatId}号位置`;
      } else {
        return "全部";
      }
    },
    formatterTime(row, column) {
      return moment(row.startTime).format("YYYY.MM.DD HH:mm:ss");
    },
    formatterETime(row, column) {
      return moment(row.endTime).format("YYYY.MM.DD HH:mm:ss");
    },
    handleCurrentChange() {
      this.getList();
    },
  },
  mounted() { },
};
</script>
<style lang="less" src="./index.less"></style>

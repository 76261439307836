<template>
  <div class="disks">
    <div class="index-list">
      <div class="tabs">
        <div v-for="(groupItem, groupIndex) in groups" :key="groupIndex" :class="[
          'tab',
          { taba: groupIndex == active },
          { tabFirst: groupIndex != 0 && groupItem.fanwei.charAt(0) == '1' },
        ]" :data-i="groupIndex" @click="cutTab">
          <div class="tab-inner">
            <div class="label">{{ groupItem.diskId }}</div>
            <div class="num">{{ groupItem.fanwei }}</div>
            <div v-if="groupItem.errNum" class="msg">
              {{ groupItem.errNum }}
            </div>
          </div>
        </div>

      </div>
      <div class="con" v-if="groups.length">
        <template v-for="(item, index) in groups[active].seats" :key="index">
          <router-link v-if="item.status != -1" :to="{
            name: 'Shit',
            query: {
              diskId: item.diskId,
              seatId: item.id,
              historyId: $route.params.historyId
                ? $route.params.historyId
                : 0,
              testStatId: 0,
              from: this.$route.name,
            },
          }" :class="{
  seat: true,
  ['seat' + item.status]: true,
  seat_no_juan: item.comStatus != 3,
  seat_juan: item.comStatus == 3,
}">
            <div class="seat-t">{{ item.id }}</div>

            <div class="seat-b">
              <span>MHI</span>
              <span>{{ parseFloat(item.mhi).toFixed(1) }}</span>
            </div>
          </router-link>
          <div v-else class="seat seat-no"></div>
        </template>
      </div>
    </div>
    <div class="index-num">
      <div class="index-num-item">
        <div class="index-num-icon index-num-icon1"></div>
        <div class="index-num-txt">数据正常&nbsp;{{ count.run1 }}</div>
      </div>
      <div class="index-num-item">
        <div class="index-num-icon index-num-icon2"></div>
        <div class="index-num-txt">数据异常&nbsp;{{ count.run2 }}</div>
      </div>
      <div class="index-num-item">
        <div class="index-num-icon index-num-icon0"></div>
        <div class="index-num-txt">未运行&nbsp;{{ count.run0 }}</div>
      </div>
      <div class="index-num-item">
        <div class="index-num-icon index-num-icon3"></div>
        <div class="index-num-txt">通信异常&nbsp;{{ count.comRun3 }}</div>
        <!-- <el-tooltip class="box-item" effect="customized" placement="top">
          <template #content>
            <div class="tip-box">
              <div class="tip-item">
                <div class="tip-icon icon1"></div>
                <div class="tip-label">通信异常</div>
              </div>
              <div class="tip-item">
                <div class="tip-icon icon2"></div>
                <div class="tip-label">数据正常但通信异常</div>
              </div>
              <div class="tip-item">
                <div class="tip-icon icon3"></div>
                <div class="tip-label">数据异常且通信异常</div>
              </div>
              <div class="tip-item">
                <div class="tip-icon icon4"></div>
                <div class="tip-label">未运行但通信异常</div>
              </div>
            </div>
          </template>
          <img class="b_icon" :src="require('./img/b_icon.png')" />
        </el-tooltip> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Page",
  props: ["disks"],
  components: {},
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    cutTab(e) {
      // console.log(e.currentTarget.dataset.i);
      this.active = e.currentTarget.dataset.i;
    },
  },
  computed: {
    groups() {
      let groups = [];
      let disks = JSON.parse(JSON.stringify(this.disks));
      disks.forEach((v, k) => {
        // console.log("盘的第一组");
        let groupinit = {
          fanwei: "1-" + (v.seats.length < 60 ? v.seats.length : "60"),
          diskId: v.id,
          seats: [],
          errNum: 0,
        };
        groups.push(groupinit);
        v.seats.forEach((vv, kk) => {
          // console.log("判断是否该换");
          // console.log(kk);

          if (
            groups[groups.length - 1].seats.length == 0 ||
            groups[groups.length - 1].seats.length % 60 != 0
          ) {
            // console.log("meiman");
            vv.diskId = v.id;
            groups[groups.length - 1].seats.push(vv);
            if (vv.status == 2) {
              groups[groups.length - 1].errNum++;
            }
          } else {
            //kk==60
            //length==45
            // console.log("manle");
            // console.log(kk);
            let groupTemp = {
              fanwei:
                kk +
                1 +
                "-" +
                (kk + (v.seats.length - kk < 60 ? v.seats.length - kk : 60)),
              diskId: v.id,
              seats: [],
              errNum: 0,
            };
            groups.push(groupTemp);
            vv.diskId = v.id;
            groups[groups.length - 1].seats.push(vv);
            if (vv.status == 2) {
              groups[groups.length - 1].errNum++;
            }
          }
        });
      });
      groups.forEach((v, k) => {
        if (v.seats.length < 60) {
          let i, n;
          n = v.seats.length;
          for (i = 0; i < 60 - n; i++) {
            // console.log("push");
            v.seats.push({ status: -1 });
          }
        }
      });
      // groups = [...groups,...groups]
      return groups;
    },
    count() {
      let o = { run0: 0, run1: 0, run2: 0, run3: 0, comRun3: 0 };
      this.groups.forEach((v, k) => {
        v.seats.forEach((vv, kk) => {
          o["run" + vv.status]++;
        });
      });
      this.groups.forEach((v, k) => {
        v.seats.forEach((vv, kk) => {
          if (vv.comStatus == 3) {
            o.comRun3++;
          }
        });
      });
      return o;
    },
  },
  mounted() { },
};
</script>
<style scoped lang="less" src="./index.less"></style>

<style lang="less">
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 15px;
  background: #132759;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: #132759;
  // right: 0;
}
</style>

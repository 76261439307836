const data = {
  code: 0,
  data: {
    disks: [
      {
        id: 1,
        seats: [
          {
            id: 1,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 2,
            mhi: "0.0",
            status: 0,
          },
          {
            id: 3,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 4,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 5,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 6,
            mhi: "9.0",
            status: 1,
          },
          {
            id: 7,
            mhi: "9.0",
            status: 1,
          },
          {
            id: 8,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 9,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 10,
            mhi: "10.0",
            status: 1,
          },
          {
            id: 11,
            mhi: "0.0",
            status: 0,
          },
          {
            id: 12,
            mhi: "0.0",
            status: 0,
          },
        ],
      },
    ],
    time: 1660705262831,
  },
};

export { data };
